<template>
  <div>
    <custom-toolbar
      :total="$store.getters['user/total_member']"
      :title="$t('Members')"
      @refresh="getMembers"
    />

    <v-card class="mb-6 mt-6">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ reponseMsg }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.user.field.name"
              label="Rechercher par le nom"
              outlined
              dense
              placeholder="Rechercher par le nom"
              hide-details
              clearable
              @input="getMembersByFields"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.user.field.keyword"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details
              clearable
              @input="getMembersByFields"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="$store.state.user.field.license_validated"
              label="Permi validé?"
              :items="selectOptions"
              outlined
              dense
              hide-details
              clearable
              @input="getMembersByFields"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['user/members']"
        :items-per-page="itemsPerPage"
        :page.sync="currentPage"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-badge
              :icon="item.is_verified ? icons.mdiCheckDecagram : icons.mdiHelp"
              bottom
              overlap
              class="h-25"
              offset-x="12"
              offset-y="12"
            >
              <v-avatar
                :color="item.profile_picture_path ? '' : 'primary'"
                :class="item.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
                size="36"
              >
                <v-img
                  v-if="item.profile_picture_path"
                  :src="`${item.profile_picture_path}`"
                ></v-img>
                <span v-else-if="item.name">{{ item.name ? item.name.slice(0,2).toUpperCase() : null }}</span>
                <v-img
                  v-else
                  :src="require('@/assets/images/avatars/1.png')"
                />
              </v-avatar>
            </v-badge>
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
              <small>{{ item.phone_number }} {{ item.email }}</small>
            </div>
          </div>
        </template>

        <template #[`item.license_validated_at`]="{item}">
          <v-chip
            v-show="item.license_validated_at !== null"
            color="info"
            small
          >
            <span class="font-weight-bold">{{ item.license_validated_at !== null ? formattingDate(`${item.license_validated_at}`) : null }}</span>
          </v-chip>
        </template>
        <template #[`item.phone_number`]="{item}">
          {{ item.phone_number !== null ? (item.country_code !== null ? `(${item.country_code})` : '') + `${item.phone_number}` : null }}
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.dynamic_balance`]="{item}">
          <v-chip
            :color="item.is_locked ? 'error' : 'success'"
            :class="`${item.is_locked ? 'error' : 'success'}--text`"
            class="text-bold"
            small
          >
            {{ formatAmount(item.dynamic_balance) }}
          </v-chip>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :edit="false"
            :status="false"
            :remove="false"
            @view="openItem(item)"
            @lock="deleteItem(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.user.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getMembersPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getMembers"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="lockUser(item)"
    />
    <loader-dialog :is-dialog-visible="loaderDialog" />
    <user-lock-form-dialog
      :dialog="dialogLock"
      :user="user"
      @clickout="closeDialogLock"
      @submitted="submitting"
      @error="errorForm"
    />
  </div>
</template>

<script>
import {
  mdiSquareEditOutline, mdiDotsVertical, mdiDeleteOutline, mdiPencilOutline, mdiAccountLock, mdiLockOpen, mdiEyeOutline, mdiRefresh,
  mdiCheckDecagram, mdiHelp,
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import UserLockFormDialog from '@core/components/user/UserLockFormDialog'
import { formatDate } from '@core/date/dateFunctions'
import { intToCurrency, notify } from '@core/dynamicComponents/dynamicComponents'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import {onMounted, ref, watch} from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'
import router from '@/router'

export default {
  name: 'Members',
  components: {
    ButtonsGroup, CustomToolbar, UserLockFormDialog, ConfirmDialog, LoaderDialog,
  },

  setup() {
    const isLockedColor = {
      false: 'error',
      true: 'success',
    }

    const itemsPerPage = ref(parseInt(store.state.user.field.paginate, 10))

    const selectOptions = [
      { text: 'Oui', value: true },
      { text: 'Non', value: false },
      { text: 'Tous', value: null },
    ]
    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const loaderDialog = ref(false)
    const isAlertVisible = ref(false)
    const error = ref(false)
    const messageDialog = ref(null)
    const reponseMsg = ref(null)
    const user = ref(null)
    const dialogLock = ref(false)

    const formattingDate = date => formatDate(date)

    const closeDialogLock = () => {
      reponseMsg.value = store.state.user.lock_message
      dialogLock.value = false
      isAlertVisible.value = !!reponseMsg
      loaderDialog.value = false
    }

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const submitting = () => {
      loaderDialog.value = true
    }

    const errorForm = () => {
      loaderDialog.value = false
    }

    const lockUser = () => {
      loaderDialog.value = true
      store.dispatch('user/lockUser', { user: user.value, field: null }).then(response => {
        reponseMsg.value = response.message
        loaderDialog.value = false
        dialogDelete.value = false
        error.value = false
        isAlertVisible.value = true
        notify(true, true, response.message)
      }).catch(err => {
        reponseMsg.value = err.response.data.message
        loaderDialog.value = false
        dialogDelete.value = false
        error.value = true
        isAlertVisible.value = true
        notify(true, true, err.response.data.message)
      })
    }

    const deleteItemConfirm = () => {
      // this.userList.splice(this.editedIndex, 1)
      closeDelete()
    }

    const deleteItem = item => {
      messageDialog.value = item.is_locked ? `Voulez-vous débloquer l'utilisateur ${item.name}?` : `Voulez-vous bloquer l\'utilisateur ${item.name}?`
      user.value = item
      if (item.is_locked) dialogDelete.value = true
      else dialogLock.value = true
    }

    const openItem = item => {
      router.push({ name: 'showuser', params: { id: item.id } })
    }

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const getMembers = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('user/getMembersList', { page, field: store.state.user.field }).then(() => {
        currentPage.value = store.getters['user/current_page']
        lastPage.value = store.getters['user/last_page']
        isDialogVisible.value = false
      }).catch(err => {
        console.log(err)
      })
    }

    // eslint-disable-next-line camelcase
    const getMembersPerPage = (per_page) => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.user.field.paginate = per_page
        itemsPerPage.value = parseInt(per_page, 10)
        store.dispatch('user/getMembersList', {
          page: currentPage.value,
          field: store.state.user.field,
        }).then(() => {
          currentPage.value = store.getters['user/current_page']
          lastPage.value = store.getters['user/last_page']
          isDialogVisible.value = false
        })
      }
    }

    const getMembersByFields = () => {
      isDialogVisible.value = true
      store.dispatch('user/getMembersList', {
        page: 1,
        field: store.state.user.field,
      }).then(() => {
        currentPage.value = store.getters['user/current_page']
        lastPage.value = store.getters['user/last_page']
        isDialogVisible.value = false
      })
    }

    watch(() => store.state.user.refreshMemberCount, () => {
      console.log("mm")
      isDialogVisible.value = true
      store.dispatch('user/refreshMembersList', { page: 1, field: store.state.user.field }).then(() => {
        currentPage.value = store.getters['user/current_page']
        lastPage.value = store.getters['user/last_page']
        isDialogVisible.value = false
      }).catch(err => {
        console.log(err)
      })
    }, { deep: true })

    onMounted(() => {
      getMembers(store.getters['user/current_page'] ? store.getters['user/current_page'] : null)
    })

    return {
      selectOptions,
      getMembersByFields,
      getMembersPerPage,
      closeDelete,
      getMembers,
      deleteItem,
      openItem,
      deleteItemConfirm,
      lockUser,
      submitting,
      errorForm,
      currentPage,
      lastPage,
      dialogLock,
      dialogDelete,
      isDialogVisible,
      loaderDialog,
      isAlertVisible,
      error,
      messageDialog,
      reponseMsg,
      user,
      itemsPerPage,
      formatAmount,
      closeDialogLock,
      formattingDate,
      headers: [
        { text: 'NOM', value: 'name' },
        { text: 'SOLDE', value: 'dynamic_balance' },
        { text: "DATE D'INSCRIPTION", value: 'created_at' },
        { text: 'PERMIS VALIDE LE', value: 'license_validated_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        0: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiCheckDecagram,
        mdiHelp,
      },
    }
  },
}
</script>

<style scoped>

</style>
